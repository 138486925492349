import React, {FC, useEffect, Suspense} from 'react';
import './App.css';
import {AuthProvider} from "./contexts/Auth";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {indexRoutes} from "./routes";
import {ConfigProvider,} from 'antd-mobile';
import fa from 'antd-mobile/es/locales/fa-IR'
import MiddlewareRoute from "./component/MiddlewareRoute";
import {Loading} from "./component";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { TimeStoreProvider } from './contexts/TimeStore';
// import { requestFirebaseNotificationPermission } from './firebaseInit'

Bugsnag.start({
    apiKey: 'a9512cbde17da064ed92027ce074be55',
    plugins: [new BugsnagPluginReact()]
})
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const App: FC = () => {


    useEffect(() => {

        // requestFirebaseNotificationPermission()
        //     .then((firebaseToken) => {
        //         // eslint-disable-next-line no-console
        //         console.log(firebaseToken);
        //     })
        //     .catch((err) => {
        //         return err;
        //     });


    }, [])

    return (
        <Suspense fallback={<Loading/>}>
            <ErrorBoundary>
                <TimeStoreProvider>
                    <ConfigProvider locale={fa}>
                        <AuthProvider>
                            <BrowserRouter>
                                <Routes>
                                    {indexRoutes.map((prop: any, key) => {
                                        return (
                                            <Route path={prop.path} key={key} element={<MiddlewareRoute {...prop}/>}/>
                                        );
                                    })}
                                </Routes>
                            </BrowserRouter>
                        </AuthProvider>
                    </ConfigProvider>
                </TimeStoreProvider>
            </ErrorBoundary>
        </Suspense>
    );
}

export default App;
