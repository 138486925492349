import {AnimatePresence, motion} from "framer-motion";
import {ImageLazy} from "../ImageLazy";
import {url} from "../../index";
import {useMemo} from "react";


const StoryItem = ({id, SetIdShow, isShow, Id, Name, Cover, File}: any) => {

    const GetType = useMemo(() => {
        const images = ["jpg", "gif", "png"]
        const videos = ["mp4", "3gp", "ogg"]

        const link = new URL(url + File)
        const extension = link.pathname.split(".")[1]

        if (images.includes(extension)) {
            return "image"
        } else if (videos.includes(extension)) {
            return "video"
        }
        return ""

    }, [File])
    return (
        <>

            <AnimatePresence>
                {isShow && <motion.div
                    initial={{scale: 0}}
                    animate={{
                        scale: 1,

                    }}
                    transition={{
                        type: "keyframes",
                        duration: 0.2
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: 0.2
                        }
                    }}

                    className="fixed z-[80] bg-[#000] h-screen w-screen flex  flex-col space-y-1.5 items-center justify-end left-0 top-0"
                    style={{flex: "1 0 auto"}} onClick={() => {
                    SetIdShow(null)
                    window.document.body.style.overflow = ""

                }}>
                    <div className="w-full h-full relative">
                        <div className="absolute top-2 left-2">
                            <div
                                className="rounded-[1.7rem]  mx-auto   bg-gradient-to-r py-[2.4px] px-[2.5px] from-[#f9a52e] via-[#1c79ec] to-[#1c79ec]">
                                <div
                                    className="rounded-3xl flex flex-col justify-between h-full bg-white text-white   p-[2px] overflow-hidden ">
                                    <ImageLazy alt="story" width="20px" height="20px" className="rounded-3xl w-[80px]"
                                               src={url + Cover}/>
                                </div>
                            </div>
                            {/*<p className="text-xs  tracking-wide text-center uppercase text-white">{Name}</p>*/}
                        </div>
                        {GetType === "image" && <img src={url + File} className="w-full h-full object-contain" alt=""/>}
                        {GetType === "video" && <video autoPlay={true} className="w-full h-full object-contain" controls={false}>
                            <source src={url + File}/>
                        </video>}


                    </div>

                </motion.div>}
            </AnimatePresence>

        </>
    );
};

export default StoryItem