import {useEffect, useState, useRef} from 'react';
import { Image } from 'antd-mobile'
export const ImageLazy = ({src, placeHolder, alt, onClick, className, height, width}: any) => {
    const [source, setSource] = useState(placeHolder);
    const refEl: any = useRef();

    useEffect(() => {
        let observer = new IntersectionObserver(
            (d: IntersectionObserverEntry[]) => {
                if (d[0].isIntersecting) {
                    setSource(src);
                    observer.disconnect();
                }
            },
        );
        if (refEl.current) {
            observer.observe(refEl.current);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [src]);

    // @ts-ignore
    return (<Image

            lazy={true}
            src={src}
            alt={alt}
            // ref={refEl}
            onClick={onClick}
            className={className} height={height} width={width}
        />
    );
};