import React from "react";
import {NotFound} from '../pages/NotFound'
import {AppOutline,UserOutline,AppstoreOutline,CalendarOutline ,TagOutline,SearchOutline} from 'antd-mobile-icons'
// import Login from "../pages/Login";
// import Splash from "../pages/Splash";
// import Introduction from "../pages/Introduction";
// import Dashboard from "../pages/Dashboard"
// import Account from "../pages/account"
// import DefaultLayout from "../layouts/Default"
// import MainLayout from "../layouts/MainLayout"

const MainLayout = React.lazy(() => import('../layouts/MainLayout'));
const DefaultLayout = React.lazy(() => import('../layouts/Default'));
const Account = React.lazy(() => import('../pages/account'));
const About = React.lazy(() => import('../pages/account/about'));
const Contact = React.lazy(() => import('../pages/account/contact/contact'));
const Tell = React.lazy(() => import('../pages/account/contact/tell/tell'));
const Ticket = React.lazy(() => import('../pages/account/contact/ticket/ticket'));
const TicketList = React.lazy(() => import('../pages/account/contact/ticket/list'));
const TicketCreate = React.lazy(() => import('../pages/account/contact/ticket/create'));
const TicketShow = React.lazy(() => import('../pages/account/contact/ticket/show'));

const Wallet = React.lazy(() => import('../pages/account/wallet/wallet'));
const WalletList = React.lazy(() => import('../pages/account/wallet/list'));
const AddMoney = React.lazy(() => import('../pages/account/wallet/AddMoney'));

const Faq = React.lazy(() => import('../pages/account/faq'));
const Invite = React.lazy(() => import('../pages/account/invite'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Splash = React.lazy(() => import('../pages/Splash'));
const Login = React.lazy(() => import('../pages/Login'));
const Introduction = React.lazy(() => import('../pages/Introduction'));
const Save = React.lazy(() => import('../pages/save'));
const Video = React.lazy(() => import('../pages/save/video'));

const Word = React.lazy(() => import('../pages/save/word'));
const WordList = React.lazy(() => import('../pages/save/word/list'));
const WordShow = React.lazy(() => import('../pages/save/word/show'));


const indexRoutes = [
    {path: '/', component: Splash},
    {path: '/login', component:Login},
    {path: '/introduction', component: Introduction },
    {path: '/dashboard/*', component:  DefaultLayout ,
        child:[
            {
                path: '/',
                name: 'خانه',
                icon: <AppOutline />,
                component:  Dashboard ,
                private: false,
                showInNav: true,
            }
        ]
    },
    {path: '/account/*', component:  MainLayout ,
        child:[
            {
                path: '/',
                name: 'حساب کاربری',
                icon: <AppOutline />,
                component:  Account ,
                private: false,
            },
            {
                path: '/about',
                name: 'درباره ما',
                icon: <AppOutline />,
                component:  About ,
                private: false,
            },
            {
                path: '/faq',
                name: 'قوانین و مقررات',
                icon: <AppOutline />,
                component:  Faq ,
                private: false,
            },
            {
                path: '/contact/*',
                name: 'تماس با ما',
                icon: <AppOutline />,
                component:  Contact ,
                private: false,
                child:[
                    {
                        path: '/tell',
                        name: 'تماس',
                        icon: <AppOutline/>,
                        component:  Tell ,
                        private: false,

                    },
                    {
                        path: '/ticket/*',
                        name: 'ارسال تیکت',
                        icon: <AppOutline/>,
                        component:  Ticket ,
                        private: false,
                        child:[
                            {
                                path: '/list',
                                name: 'لیست تیکت',
                                icon: <AppOutline/>,
                                component:  TicketList ,
                                private: false,
                            },
                            {
                                path: '/show/:id',
                                name: 'تیکت',
                                icon: <AppOutline/>,
                                component:  TicketShow ,
                                private: false,
                            },
                            {
                                path: '/create',
                                name: 'تیکت',
                                icon: <AppOutline/>,
                                component:  TicketCreate ,
                                private: false,
                            },
                        ]
                    },
                ]
            },
            {
                path: '/invite',
                name: 'دعوت از دوستان',
                icon: <AppOutline />,
                component:  Invite ,
                private: false,
            },
            {
                path: '/wallet/*',
                name: 'کیف پول',
                icon: <AppOutline />,
                component:  Wallet ,
                private: false,
                child:[
                    {
                        path: '/',
                        name: 'لیست تراکنش ها',
                        icon: <AppOutline />,
                        component:  WalletList ,
                        private: false,
                    },
                    {
                        path: '/add-money',
                        name: 'کیف پول',
                        icon: <AppOutline />,
                        component:  AddMoney ,
                        private: false,
                    },
                ]
            }
        ]

    },
    {path: '/save/*', component:  MainLayout ,
        child:[
            {
                path: '/',
                name: 'ذخیره های من',
                icon: <AppOutline />,
                component:  Save ,
                private: false,
            },
            {
                path: '/video',
                name: 'ویدیو ها',
                icon: <AppOutline />,
                component:  Video ,
                private: false,
            },
            {
                path: '/word/*',
                name: 'لغت ها',
                icon: <AppOutline />,
                component:  Word ,
                private: false,
                child:[
                    {
                        path: '/',
                        name: 'لغات زبان',
                        icon: <AppOutline />,
                        component:  WordList ,
                        private: false,
                    },
                    {
                        path: '/show',
                        name: 'لغت زبان',
                        icon: <AppOutline />,
                        component:  WordShow ,
                        private: false,
                    },
                ]
            },
        ]
    },
    {path: '/planning/*', component:  MainLayout ,
        child:[

        ]
    },
    {path: '/search/*', component:  MainLayout ,
        child:[

        ]
    },
    {path: '*', component:  NotFound },
]

const NavTabRoutes = [
    {
        path: '/dashboard',
        name: 'خانه',
        icon: <AppstoreOutline />,
    },
    {
        path: '/planning',
        name: 'برنامه ریزی',
        icon: <CalendarOutline />,
    },
    {
        path: '/save',
        name: 'ذخیره',
        icon: <TagOutline />,
    },
    {
        path: '/search',
        name: 'جستجو',
        icon: <SearchOutline />,
    },
    {
        path: '/account',
        name: 'حساب کاربری',
        icon: <UserOutline />,
    },

]



export {indexRoutes,NavTabRoutes}
