import StoryItem from "./StoryItem";
import {LayoutGroup, motion} from "framer-motion";

import {Swiper, SwiperSlide} from "swiper/react";

import StoryItemShow from "./StoryItemShow";
import {useState} from "react";

const Index = ({data,loading}:any) => {


    const range = (start: number, end: number) => {
        return Array(end - start + 1).fill(null).map((_, idx) => start + idx)
    }
    const [IdShow, SetIdShow] = useState<any>(null)


    return (
        <>
            <LayoutGroup id={"story"}>
                <motion.div layout className="py-3 px-3 mx-[-0.75rem] ">
                    <Swiper

                        dir="rtl"
                        slidesPerView={3.3}

                        spaceBetween={0}
                        breakpoints={{
                            640: {
                                slidesPerView: 5.8,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 6.3,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 10.8,
                                spaceBetween: 0,
                            },
                        }}
                        // loop={true}
                    >
                        {data?.GetSetting?.storyData?.items?.map((item:any)=>
                            <SwiperSlide key={item.Id}>
                                <StoryItem id={item.Id} {...item} SetIdShow={SetIdShow}/>
                            </SwiperSlide>)
                        }
                    </Swiper>
                    {/*{[...range(1, 50).map((item) => (*/}
                    {/*    <StoryItemShow isShow={item === IdShow} key={item} id={item} SetIdShow={SetIdShow}/>*/}
                    {/*))]}*/}
                    {data?.GetSetting?.storyData?.items?.map((item:any)=>
                        <SwiperSlide key={item.Id}>
                            <StoryItemShow isShow={item.Id === IdShow} key={item.Id} {...item} id={item.Id} SetIdShow={SetIdShow}/>
                        </SwiperSlide>)
                    }
                </motion.div>
            </LayoutGroup>

        </>
    );
};

export default Index




