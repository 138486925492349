import {motion} from "framer-motion";
import ReferenceItem from "./ReferenceItem";
import {Swiper, SwiperSlide} from "swiper/react";
import {gql, useQuery} from "@apollo/client";

const Index = ({data,loading}:any) => {



    return (
        <div className="py-5 ">

                <div className="flex items-center justify-between">
                    <p className="text-base text-gray-800">منابع درسی</p>
                    <p className="text-xs text-gray-500">مشاهده همه</p>
                </div>
                <motion.div layout className="py-3 px-1  mx-[-0.75rem] pr-[0.75rem]  ">
                    <Swiper

                        dir="rtl"
                        slidesPerView={3.2}
                        spaceBetween={0}
                        breakpoints={{
                            640: {
                                slidesPerView: 3.8,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 6.3,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 10.3,
                                spaceBetween: 0,
                            },
                        }}
                        // loop={true}
                    >
                        {data?.GetSetting?.bookData?.items?.map((item:any)=>
                            <SwiperSlide key={item.Id}>
                                <ReferenceItem key={item.Id} {...item}/>
                            </SwiperSlide>)
                        }
                        {/*{[...range(1, 50).map((item) => (*/}

                        {/*    <SwiperSlide key={item}>             <ReferenceItem key={item}/> </SwiperSlide>*/}
                        {/*))]}*/}


                    </Swiper>

                </motion.div>

        </div>
    );
};

export default Index