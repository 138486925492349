// import {Swiper} from "antd-mobile"
import BannerItem from "./BannerItem";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import {Navigation} from "swiper";




const Index = () => {

    return (
        <div className="py-3" dir="ltr">
            <Swiper
                dir="rtl"
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                }}
                modules={[Navigation]}
                navigation={window.innerWidth >= 1024}
            >
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
                <SwiperSlide> <BannerItem/> </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Index