import {AppstoreOutline} from "antd-mobile-icons"


const ShowPageButton = ({text,icon=<AppstoreOutline/>}:any) => {

 return (
     <div className="w-full h-16 sm:h-[5rem] bg-white shadow-xl shadow-[#0000000f] rounded-2xl flex flex-col items-center justify-center">
                 <span className="text-2xl mb-2 text-yellow-400"> {icon} </span>
         <span className="text-xs tracking-wide text-center text-gray-600 uppercase">{text}</span>
     </div>
 );
};

export default ShowPageButton