import img  from "../../assets/image/App/video.webp"
import {ImageLazy} from "../ImageLazy";
const VideoItem = () => {

    return (
        <div className="inline-flex px-2 w-full flex-col space-y-2 justify-center  flex-[1_0_auto]"  >
            <ImageLazy width={"100%"} height={128} className="w-full h-[128px] rounded-lg" src={img} alt={"video"}/>
            <div className="flex flex-col space-y-1  w-full justify-center pr-2">
                <p className="text-sm text-gray-800">سینماتیک و دینامیک</p>
                <p className="text-xs tracking-wide text-gray-500 uppercase">استاد برین</p>
            </div>
        </div>
    );
};

export default VideoItem