import "core-js/es6/map"
import "core-js/es6/set"
import "core-js/es6/promise"
import "core-js/es6/object"
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/vazir/Vazirmatn-font-face.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "swiper/css";

import {ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, from} from '@apollo/client';

import {createUploadLink} from 'apollo-upload-client';
import {onError} from '@apollo/client/link/error';

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)
let url:any = null
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    url = "http://localhost:8080/"
} else {
    url ="https://api.yarido.ir/"
}
const httpLink = createUploadLink({uri: url+"query"});


const Main = () => {
    // const toast = useToast()
    const roundTripLink = new ApolloLink((operation, forward) => {
        // Called before operation is sent to server
        operation.setContext({start: new Date()});

        return forward(operation).map((data) => {
            // Called after server responds
            // @ts-ignore
            const time = new Date() - operation.getContext().start;
            console.log(`Operation ${operation.operationName} took ${time} to complete`);
            return data;
        });
    });
    const logoutLink = onError(({networkError, graphQLErrors}) => {
        // if (networkError.statusCode === 401) logout();
        console.log(networkError)
        graphQLErrors?.forEach((item) => {
            console.log(item)
            // toast({
            //     title: 'مشکلی در سرور به وجود آمده است!!',
            //     description: item.message+" "+item.path?.join("_"),
            //     status: 'error',
            //     duration: 5000,
            //     isClosable: false,
            //     position:"top-left"
            // })
        })

    })

    const client = new ApolloClient({
        uri: url+'query',
        cache: new InMemoryCache({}),
        link: from([roundTripLink.concat(logoutLink.concat(httpLink))]),
    });

    return (
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    )
}
root.render(<Main/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event: any) => {
                if (event.target.state === "activated") {
                    window.location.reload()
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


window.addEventListener("touchstart", (e) => {
    if (e.touches.length > 1) {
        e.preventDefault()
    }
}, {passive: false});

export {url}