import {useAuth} from '../contexts/Auth'
import React from 'react'
import {Navigate} from 'react-router-dom'

const ProtectedRoute = (props: any) => {
    const {
        user: {loggedIn},
    } = useAuth()


    return <React.Fragment>
        {loggedIn === true ? props.Component : <Navigate to="/login"/>}
    </React.Fragment>
}

export default ProtectedRoute
