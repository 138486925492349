import {ImageLazy} from "../ImageLazy";
import {url} from "../../index";

const ReferenceItem = ({  Id,Name,Cover}:any) => {

    return (
        <div className="px-3 inline-flex flex-col space-y-2 items-start justify-center   flex-[1_0_auto]">
            <ImageLazy width={92} height={128} className="w-[92px] h-[128px] rounded-lg" src={url+Cover} alt="reference"/>
            <p className="text-sm text-gray-800 pr-2"> {Name}</p>
        </div>
    )
};

export default ReferenceItem