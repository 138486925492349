import {motion} from "framer-motion";
import VideoItem from "../video/VideoItem";
import {Swiper, SwiperSlide} from "swiper/react";

const Index = () => {

    const range = (start: number, end: number) => {
        return Array(end - start + 1).fill(null).map((_, idx) => start + idx)
    }

    return (
        <div className="py-5 mt-3">
            <div className="flex items-center justify-between">
                <p className="text-base text-gray-800">ویدیو ها</p>
                <p className="text-xs text-gray-500">مشاهده همه</p>
            </div>
            <motion.div layout className=" py-3 px-1 mx-[-0.75rem] pr-[0.75rem] ">
                <Swiper

                    dir="rtl"
                    slidesPerView={2.2}
                    spaceBetween={0}
                    breakpoints={{
                        640: {
                            slidesPerView: 3.8,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 4.8,
                            spaceBetween: 0,
                        },
                        1024: {
                            slidesPerView: 7.8,
                            spaceBetween: 0,
                        },
                    }}
                    // loop={true}
                >
                    {[...range(1, 30).map((item) => (
                        <SwiperSlide key={item}>  <VideoItem/> </SwiperSlide>
                    ))]}


                </Swiper>

            </motion.div>
        </div>
    );
};

export default Index