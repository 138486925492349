import banner from "../../assets/image/App/banner-min.webp";
import {ImageLazy} from "../ImageLazy";


const BannerItem = () => {

 return (
  <div className="px-4">
      <ImageLazy src={banner} alt="banner" width="100%" height="auto" />
  </div>
 );
};

export default BannerItem
