import {createContext, useContext, useEffect, useState} from 'react'

const TimeStoreContext = createContext<TimeStoreContextValue | undefined>(undefined)

const setLocalStorage = (key: any, value: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
        console.error({e})
    }
}

const getLocalStorage = (key: any, initialValue: any) => {
    try {
        const value = localStorage.getItem(key)
        return value ? JSON.parse(value) : initialValue
    } catch (e) {
        return initialValue
    }
}


const TimeStoreProvider = ({children}: any) => {
    const [IsStart, setIsStart] = useState(() =>
        getLocalStorage('time', false),
    )
    const [TimeSecond, setTimeSecond] = useState(() =>
        getLocalStorage('TimeSecond', []),
    )


    const toggleTimeStore = (IsStart: any) => {
        setIsStart(IsStart)
        if (TimeSecond.length === 0 && IsStart) {
            setTimeSecond([{start:new Date().getTime(),end:null}])
        }else if(TimeSecond.length !== 0){
            if(IsStart){
                let arr:any = TimeSecond
                arr.push( {start:new Date().getTime(),end:null})
                setTimeSecond(arr)
            }else {
                let arr:any = TimeSecond
                arr[arr.length-1].end = new Date().getTime()
                setTimeSecond(arr)
            }
        }
    }


    useEffect(() => {
        setLocalStorage('time', IsStart)
        setLocalStorage('TimeSecond', TimeSecond)
    }, [IsStart,TimeSecond])


    const GetSecond = () => {
        if (TimeSecond.length === 0) {
            return 0;
        }

        if (TimeSecond.length === 1 && TimeSecond[TimeSecond.length-1].end === null) {
            return (new Date().getTime() - TimeSecond[0].start  )/1000;
        }

        return TimeSecond.map((item:any)=>{
            if(item.end === null){
                return new Date().getTime() - item.start
            }else {
               return  item.end - item.start
            }
        }).reduce(function (previousValue:any, currentValue:any) {
            return previousValue + currentValue;
        })/1000
    }
    const ResetSecond = () => {
        setTimeSecond([])
    }


    const value: any = {toggleTimeStore: toggleTimeStore, IsStart: IsStart, GetSecond: GetSecond,ResetSecond:ResetSecond}

    return <TimeStoreContext.Provider value={value}>{children}</TimeStoreContext.Provider>
}


interface TimeStoreContextValue {
    toggleTimeStore: (IsOpen: boolean) => void,
    IsStart: boolean,
    GetSecond: () => number,
    ResetSecond: () => void,

}

const useTimeStore = () => {
    const context: TimeStoreContextValue | undefined = useContext(TimeStoreContext)

    if (context === undefined)
        throw new Error('useTimeStore must be within TimeStoreProvider!')

    return context
}

export {TimeStoreProvider, useTimeStore}
