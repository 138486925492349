import React from 'react'


const Loading = () => {
    return (
        <div className="flex items-center justify-center h-screen w-screen bg-blue-900 text-white">
            <span>لطفا صبر کنید...</span>
        </div>
    )
}

export default Loading
