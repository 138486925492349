import avatar from "../../assets/image/img-avatar.webp";
import { motion} from "framer-motion";
import {ImageLazy} from "../ImageLazy";
import {url} from "../../index";


const StoryItem = ({id,SetIdShow, Id,Name,Cover}:any) => {


    return (

            <motion.div layoutId={id} className="flex px-3  flex-col space-y-1.5 items-center justify-end left-0 top-0"
                        style={{flex: "1 0 auto"}} onClick={()=>{SetIdShow(id);window.document.body.style.overflow = "hidden"}}>
                <div
                    className="rounded-[1.7rem]  mx-auto   bg-gradient-to-r py-[2.4px] px-[2.5px] from-[#f9a52e] via-[#1c79ec] to-[#1c79ec]">
                    <div
                        className="rounded-3xl flex flex-col justify-between h-full bg-white text-white   p-[2px] overflow-hidden ">
                        <ImageLazy alt="story" width="80px" height="80px" className="rounded-3xl w-[80px]" src={url+Cover}/>
                    </div>
                </div>
                <p className="text-xs  tracking-wide text-center uppercase"> {Name}  </p>

            {/*<AnimatePresence>*/}
            {/*    {isOpen && <motion.div  layoutId="main"*/}
            {/*                           className="fixed z-20 bg-[#000] h-screen w-screen flex  flex-col space-y-1.5 items-center justify-end left-0 top-0"*/}
            {/*                           style={{flex: "1 0 auto" }} onClick={toggleSwitch}>*/}
            {/*        <div*/}
            {/*            className="rounded-[1.7rem]  mx-auto   bg-gradient-to-r py-[2.4px] px-[2.5px] from-[#f9a52e] via-[#1c79ec] to-[#1c79ec]">*/}
            {/*            <div*/}
            {/*                className="rounded-3xl flex flex-col justify-between h-full bg-white text-white   p-[2px] overflow-hidden ">*/}
            {/*                <ImageLazy alt="story" width="80px" height="80px" className="rounded-3xl w-[80px]" src={avatar}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <p className="text-xs  tracking-wide text-center uppercase text-white">زیست شناسی {id}</p>*/}
            {/*    </motion.div>}*/}
            {/*</AnimatePresence>*/}
            </motion.div>
    );
};

export default StoryItem