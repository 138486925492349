// @flow
import * as React from 'react';

type Props = {

};
export const NotFound = (props: Props) => {
    return (
        <div>
            NotFound
        </div>
    );
};